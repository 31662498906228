import React, { useState } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import Header from "root/sections/Header";
import Footer from "root/sections/Footer";
import HomePerfectFor from "root/sections/HomePerfectFor";
import SEO from "root/components/SEO";
import Layout from "root/components/Layout";
import Button from "root/components/Button";
import Typography from "root/components/Typography";
import Section from "root/components/Section";
import BlogPostPreview from "root/components/BlogPostPreview";
import BlogFeaturedPostPreview from "root/components/BlogFeaturedPostPreview";
import arrowLeft from "root/assets/arrow-left.svg";
import arrowRight from "root/assets/arrow-right.svg";

import styles from "./blog.module.css";

export const query = graphql`
  query {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/posts/" } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          frontmatter {
            path
            author {
              childAuthorsJson {
                name
                position
                photo {
                  image: childImageSharp {
                    fluid(maxWidth: 200, quality: 75) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            date(formatString: "MMM D, YYYY")
            title
            description
            category
            time
            featuredImage {
              image: childImageSharp {
                fluid(maxWidth: 1440, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

const BlogPage = ({
  data: {
    allMdx: { edges },
  },
}) => {
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("");
  let currentPosts = edges;

  if (filter !== "") {
    currentPosts = edges.filter(
      ({ node }, index) => node.frontmatter.category === filter || index === 0,
    );
  }

  const totalPages = 1 + Math.floor((currentPosts.length - 2) / 15);

  const changeFilter = category => event => {
    event.preventDefault();

    if (filter === category) setFilter("");
    else {
      setFilter(category);
      setPage(1);
    }
  };

  const lastPage = () => {
    if (page > 1) {
      setPage(page - 1);
      window.scrollTo(0, 0);
    }
  };

  const nextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
      window.scrollTo(0, 0);
    }
  };

  const renderFeaturedPostPreview = () => {
    if (!currentPosts[0]) return null;

    const author = currentPosts[0].node.frontmatter.author.childAuthorsJson;

    return (
      <div
        key={currentPosts[0].node.frontmatter.path}
        className={styles.featuredPreview}
      >
        <BlogFeaturedPostPreview
          {...currentPosts[0].node.frontmatter}
          author={author}
        />
      </div>
    );
  };

  return (
    <div>
      <SEO title="Drivit | Blog" />
      <Layout>
        <Header
          currentPage="blog"
          background={page === 1 ? "lightGrey" : "white"}
        />
        {page === 1 ? (
          <div className={styles.featuredPostSection}>
            <div className={styles.featuredPostSectionContent}>
              {renderFeaturedPostPreview()}
            </div>
          </div>
        ) : null}
        <Section>
          <div className={styles.filters}>
            <Button
              color="none"
              size="auto"
              handleClick={changeFilter("Inside Drivit")}
            >
              <Typography
                weight={filter === "Inside Drivit" ? "bold" : "regular"}
                color={filter === "Inside Drivit" ? "green" : "black"}
              >
                Inside Drivit
              </Typography>
            </Button>
            <span className={styles.bulletPoint}> &bull; </span>
            <Button
              color="none"
              size="auto"
              handleClick={changeFilter("Customer Story")}
            >
              <Typography
                weight={filter === "Customer Story" ? "bold" : "regular"}
                color={filter === "Customer Story" ? "green" : "black"}
              >
                Customer Story
              </Typography>
            </Button>
            <span className={styles.bulletPoint}> &bull; </span>
            <Button
              color="none"
              size="auto"
              handleClick={changeFilter("News & Updates")}
            >
              <Typography
                weight={filter === "News & Updates" ? "bold" : "regular"}
                color={filter === "News & Updates" ? "green" : "black"}
              >
                News &amp; Updates
              </Typography>
            </Button>
          </div>
          <div className={styles.postList}>
            {currentPosts
              .slice(1 + (page - 1) * 15, 16 + (page - 1) * 15)
              .map(({ node }) => {
                const author = node.frontmatter.author.childAuthorsJson;

                return (
                  <div key={node.frontmatter.path} className={styles.preview}>
                    <BlogPostPreview {...node.frontmatter} author={author} />
                  </div>
                );
              })}
          </div>
          {totalPages <= 1 ? null : (
            <div className={styles.pagination}>
              <Button color="none" size="auto" handleClick={lastPage}>
                <img src={arrowLeft} alt="" className={styles.arrowLeft} />
              </Button>
              <Typography>
                <b>{page}</b>
                {` / ${totalPages}`}
              </Typography>
              <Button color="none" size="auto" handleClick={nextPage}>
                <img src={arrowRight} alt="" className={styles.arrowRight} />
              </Button>
            </div>
          )}
        </Section>
        {page === 1 ? <HomePerfectFor /> : null}
        <Footer backgroundColor="lightGrey" />
      </Layout>
    </div>
  );
};

BlogPage.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({ edges: PropTypes.arrayOf(PropTypes.any) }),
  }).isRequired,
};

export default BlogPage;
