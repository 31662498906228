import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Img from "gatsby-image";

import Typography from "root/components/Typography";

import styles from "./index.module.css";

function BlogPostPreview({
  title,
  description,
  category,
  author,
  path,
  featuredImage,
  date,
  time,
}) {
  return (
    <div>
      <div className={styles.root}>
        <Link className={styles.link} to={`/blog/${path}`}>
          <Img
            className={styles.image}
            alt="preview"
            fluid={featuredImage.image.fluid}
            objectFit="cover"
          />
        </Link>

        <div className={styles.category}>
          <Typography color="green" weight="bold" variant="smallBody">
            {category}
          </Typography>
        </div>
        <Link className={styles.link} to={`/blog/${path}`}>
          <div className={styles.title}>
            <Typography weight="bold">{title}</Typography>
          </div>

          <div className={styles.description}>
            <Typography variant="smallBody">{description}</Typography>
          </div>
        </Link>
      </div>

      <div className={styles.author}>
        <Img
          className={styles.authorImg}
          alt="preview"
          fluid={author.photo.image.fluid}
          objectFit="cover"
        />
        <div className={styles.authorText}>
          <div className={styles.authorName}>
            <Typography color="grey" variant="smallBody" weight="bold">
              {author.name}
            </Typography>
          </div>
          <div className={styles.authorPosition}>
            <Typography color="grey" variant="smallBody">
              {date}
              <span> · </span>
              {time}
              <span> min read</span>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

BlogPostPreview.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  time: PropTypes.number.isRequired,
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    photo: PropTypes.shape({
      image: PropTypes.shape({
        fluid: PropTypes.shape({}).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  path: PropTypes.string.isRequired,
  featuredImage: PropTypes.shape({
    image: PropTypes.shape({
      fluid: PropTypes.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
};

export default BlogPostPreview;
